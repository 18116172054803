<template>

<div class="col-12"> 
 <h2>エアコンの制御</h2>


<div class=" offset-2 col-8" >
<div class=" offset-3 col-6" style="background-color:#F2F2F2; padding: 1%;">
 <input class="col-12" placeholder="スマートリモコンのDeviceID" v-model="parentId" /> <br>
 <input class="col-12" placeholder="エアコンのDeviceID" v-model="childId" />


</div>

<button type="button" class="btn btn-danger" v-on:click="setMode(1)" style="margin:1%;">暖房</button> 
<button type="button" class="btn btn-info" v-on:click="setMode(0)" style="margin:1%;">冷房</button>
<br>
<!--
<button type="button" class="btn btn-success" v-on:click="setMode(3)" style="margin:1%;">除湿</button> 
<button type="button" class="btn btn-light" v-on:click="setMode(1)" style="margin:1%;">自動</button>
<br>
-->
<button type="button" class="btn btn-light" style="margin:1%;" v-on:click="setPower(0)" >停止</button>
<button type="button" class="btn btn-light" style="margin:1%;" v-on:click="setWind()" >風速</button> <br>
<button type="button" class="btn btn-light" style="margin:1%;" v-on:click="setTemp(true)" >↑</button> <br>
室温 <br>
<button type="button" class="btn btn-light" style="margin:1%;" v-on:click="setTemp(false)" >↓</button> <br>
<br>

  </div>
</div>

</template>

<script>

import { faEye , faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { defineComponent } from "vue";

import axios from 'axios';

export default defineComponent({
  name: "EERAirCon",
  data : function() {return { 
  parentId : "",
  childId :"", 
  status: {
      power : -1 ,
      mode : -1 ,
      wind : -1 ,
      temp: -1
  }
  } },
  created : function(){
 
  axios.get(
        process.env.VUE_APP_API_URL+"TAPI?p=/v1.0/infrareds/{infrared_id}/remotes/{remote_id}/ac/status&infrared_id="+ this.parentId + "&remote_id="+ this.childId + process.env.VUE_APP_API_CODE.replace("?code","&code")
        ,
        { withCredentials: true }
        )
       .then( function( json ) {
           console.log(json);
            if( json.data){
            this.status = json.data.result ;
                return true;
            }
            return false; 
      }.bind(this));  

   },
   
   methods: {
    getPowerLabel( b ){
        if(b===-1) return "未設定";
        return b ? "ON" : "OFF" ;
    },
    getWindLabel ( num ){
          switch (Number(num)) { 
             case 1: 
            return "弱";
            case  2:
            return "中";
            case 3:
            return "強"; 
            /*case 0 :
            return "Speed";
            case 1: 
            return "オート";
            case  2:
            return "弱";
            case 3:
            return "中"; 
            case 4:
            return "強";*/
        }
        return "未設定";
    },
    getTempLabel ( num ){
        return num === -1 ? "未設定" : num ;
    },
    getModeLabel( num ){
    
        switch ( Number(num) ) { 
            case 0 :
            return "冷房";
            case 1: 
            return "暖房";
            case  2:
            return "オート";
            case 3:
            return "ファン"; 
            case 4:
            return "ドライ";
        }
          
        return "未設定" ;
    },
     setPower: function ( b ){
         
     axios.post(
        process.env.VUE_APP_API_URL+"TAPI?p=/v1.0/infrareds/{infrared_id}/air-conditioners/{remote_id}/scenes/command&infrared_id="+ this.parentId+ "&remote_id="+this.childId + process.env.VUE_APP_API_CODE.replace("?code","&code")
        ,{
            "power" : b , 
         },
        { withCredentials: true }
        )
       .then( function( json ) {
           console.log(json);
            if( json.data.result){
            
                this.status.power = b;
                 return true;
            }
            return false; 
      }.bind(this));      
    } , 
    setWind: function ( ){
  
    var num = Number( this.status.wind );
    if( num < 3 ){  num = num + 1; } else{ num = 1; }
    
    axios.post(
        process.env.VUE_APP_API_URL+"TAPI?p=/v1.0/infrareds/{infrared_id}/air-conditioners/{remote_id}/scenes/command&infrared_id="+ this.parentId+ "&remote_id="+this.childId + process.env.VUE_APP_API_CODE.replace("?code","&code")
        ,{
            "power" : 1 , 
            "wind": num
         },
        { withCredentials: true }
        )
       .then( function( json ) {
            if( json.data.result){
           
                this.status.power = 1;
                this.status.wind = num;
                 return true;
            }
            return false; 
      }.bind(this));      
    },
    setMode: function ( num ){
        
    axios.post(
        process.env.VUE_APP_API_URL+"TAPI?p=/v1.0/infrareds/{infrared_id}/air-conditioners/{remote_id}/scenes/command&infrared_id="+ this.parentId+ "&remote_id="+this.childId + process.env.VUE_APP_API_CODE.replace("?code","&code")
        ,{
            "power" : 1 , 
            "mode": num
         },
        { withCredentials: true }
        )
       .then( function( json ) {
           console.log(json);
            if( json.data.result){
            
                this.status.power = true;
                this.status.mode = num;
                return true;
            }
            return false; 
      }.bind(this));
            
    },setTemp: function (  up ){
        

    var num = this.status.temp <= 16 ? 16 : this.status.temp ;

    if(up){ num++; } else{ num --; }
   
    if(16 > num ){  alert("16度以上に設定してください。");  return; }
    if( num > 30 ){  alert("30度以下に設定してください。"); return; }
        
    axios.post(
        process.env.VUE_APP_API_URL+"TAPI?p=/v1.0/infrareds/{infrared_id}/air-conditioners/{remote_id}/scenes/command&infrared_id="+ this.parentId+ "&remote_id="+this.childId + process.env.VUE_APP_API_CODE.replace("?code","&code")
        ,{
            "power" : 1 , 
            "temp": num
         },
        { withCredentials: true }
        )
       .then( function( json ) {
           console.log(json);
            if( json.data.result){
                this.status.power = true;
                this.status.temp = num;
                 return true;
            }
            return false; 
      }.bind(this));      
    
    }  
       
   }
});

</script>
.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}

.eye::after {
  font-family: 'FontAwesome';
  content: "\f06e";
}

.eye-slash::after {
  font-family: 'FontAwesome';
  content: "\f070";
}

.input-icon {
  position: relative;
  float: right;
  margin-top: -25px;
}